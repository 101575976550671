import { useParams } from '@reach/router';
import { Link, graphql } from 'gatsby';
import gql from 'graphql-tag';
import queryString from 'query-string';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { useGetPlayerGamePageLink } from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTime, useTranslate } from '@/contexts';
import formatMoney from '@/utils/formatter/formatMoney';
import { makeFluidThumbnail } from '@/utils/makeFluidThumbnail';
import {
  PlayerGamesBox,
  PlayerGamesBoxVariables,
} from './__generated__/PlayerGamesBox';
import { SanityPlayerGamesBlockFragment } from './__generated__/SanityPlayerGamesBlockFragment';

export const Fragment = graphql`
  fragment SanityPlayerGamesBlockFragment on SanityPlayerGamesBlock {
    title {
      ...LocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerGamesBox(
    $playerId: ID!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
    $limit: Int!
  ) {
    player(playerId: $playerId) {
      id
      stats(
        granularity: All
        from: $from
        to: $to
        orderBy: [{ column: "turnover", order: DESCENDING }]
        limit: $limit
      ) {
        rows {
          gameDescriptor {
            json
          }
          turnover
          currency
          os
        }
      }
    }
  }
`;

const DeviceIcon: FC<{ os: string }> = ({ os }) => <div>{os}</div>;

const PlayerGamesBlock: FC<{
  block: SanityPlayerGamesBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const { graphQLVariables } = useTime();
  const limit = 6;

  const [{ data, fetching }, refresh] = useQuery<
    PlayerGamesBox,
    PlayerGamesBoxVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
      ...graphQLVariables,
      limit,
    },
  });

  const gamePath = useGetPlayerGamePageLink()(params.playerId);

  return (
    <Card
      size="md"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {!fetching &&
            data?.player?.stats?.rows.map((row, index) => {
              const game = row.gameDescriptor?.json;
              const fluid = makeFluidThumbnail(
                game?.thumbnail,
                game?.thumbnailUpdatedAt,
              );
              return (
                game && (
                  <Link
                    to={`${gamePath}?${queryString.stringify({
                      gameId: game.gameId,
                    })}`}
                    key={index}
                  >
                    <picture>
                      <source sizes={fluid.sizes} srcSet={fluid.srcSet} />
                      <img src={fluid.src} alt={game?.name} loading="lazy" />
                    </picture>
                    {row.os && <DeviceIcon os={row.os} />}
                    <dl>
                      <dt>Turnover</dt>
                      <dd>{formatMoney(row.turnover, row.currency)}</dd>
                    </dl>
                  </Link>
                )
              );
            })}
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerGamesBlock;
